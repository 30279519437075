// @file CSS utilities
import { kebabCase, mapKeys, mapValues } from 'lodash-es'

interface HTMLStyleElementWrapper {
  style: HTMLStyleElement
  remove: () => void
  update: (updatedCssString: string) => void
}

const appendedCss = new Map<string, HTMLStyleElementWrapper>()

/**
 * Append custom stylesheet to document head.
 * Duplicate stylesheets are not appended.
 */
function appendCssToDocumentHead(cssString: string): HTMLStyleElementWrapper {
  if (appendedCss.has(cssString)) {
    return appendedCss.get(cssString) as HTMLStyleElementWrapper
  }
  const styleTag = document.createElement('style')
  styleTag.innerHTML = cssString
  document.head.appendChild(styleTag)
  const customStyle = {
    style: styleTag,
    remove() {
      styleTag.remove()
      appendedCss.delete(cssString)
    },
    update(updatedCssString: string) {
      appendedCss.delete(cssString)
      styleTag.innerHTML = cssString
      appendedCss.set(updatedCssString, customStyle)
    },
  }
  appendedCss.set(cssString, customStyle)
  return customStyle
}

/**
 * Turns an object into a CSS custom properties object.
 * Keys are kebab-cased and prefixed with '--'.
 * Values are converted to strings and suffixed with the specified unit if they are numbers.
 */
function toCssCustomProperties(
  obj: Record<string, string | number>,
  { numericUnit }: { numericUnit?: string } = {},
): Record<string, string> {
  return mapKeys(
    mapValues(obj, (value) =>
      Number.isNaN(Number(value)) || numericUnit == null ? value.toString() : `${value}${numericUnit}`,
    ),
    (_, key) => `--${kebabCase(key)}`,
  )
}

export { appendCssToDocumentHead, toCssCustomProperties }
export type { HTMLStyleElementWrapper }
